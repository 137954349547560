/********************************************************************************
*                                                                               *
* This document and any information contained herein is the property of         *
* Eddyfi NDT Inc. and is protected under copyright law. It must be considered   *
* proprietary and confidential and must not be disclosed, used, or reproduced,  *
* in whole or in part, without the written authorization of Eddyfi NDT Inc.     *
*                                                                               *
* Le pr�sent document et l�information qu�il contient sont la propri�t�         *
* exclusive d�Eddyfi NDT Inc. et sont prot�g�s par la loi sur le droit          *
* d�auteur. Ce document est confidentiel et ne peut �tre divulgu�, utilis� ou   *
* reproduit, en tout ou en partie, sans l'autorisation �crite d�Eddyfi NDT Inc. *
*                                                                               *
********************************************************************************/

import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n'
import registerServiceWorker from './registerServiceWorker';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');

ReactDOM.render(
  <BrowserRouter basename={baseUrl}>
    <I18nextProvider i18n={i18n}>
      <App />
    </I18nextProvider>,
  </BrowserRouter>,
  rootElement);

registerServiceWorker();

